import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { useContext, useEffect, useState } from "react";
import { checkAndLoadState, hasValidSession } from "../helpers/SessionHelper";
import LoginPage from "../page/LoginPage";
import { Loading } from "../components/molecules/Loading";
import { PlaygroundsPage } from "../page/PlaygroundsPage";
import { PlaygroundsEditGeneralPage } from "../page/PlaygroundsEditGeneralPage";
import { PlaygroundsNewPage } from "../page/PlaygroundsNewPage";
import { PlaygroundsEditPhasePage } from "../page/PlaygroundEditPhasePage";
import { PlaygroundEditQuestionnairePage } from "../page/PlaygroundEditQuestionnairePage";
import { PlaygroundNewQuestionnairePage } from "../page/PlaygroundNewQuestionnairePage";
import { PlaygroundEditAddressesPage } from "../page/PlaygroundEditAddressesPage";
import { PlaygroundNewAddressPage } from "../page/PlaygroundNewAddressPage";
import { PlaygroundEditMoodboards } from "../page/PlaygroundEditMoodboardsPage";
import { PlaygroundEditActivitiesPage } from "../page/PlaygroundEditActivitiesPage";
import { PlaygroundEditIngredientsPage } from "../page/PlaygroundEditIngredientsPage";
import { PlaygroundEditConceptsPage } from "../page/PlaygroundEditConceptsPage";
import { PlaygroundEditConceptPage } from "../page/PlaygroundEditConceptPage";
import { PlaygroundNewConceptPage } from "../page/PlaygroundNewConceptPage";
import { PlaygroundResultsPage } from "../page/PlaygroundResultsPage";
import { MoodboardsPage } from "../page/MoodboardsPage";
import { ActivitiesPage } from "../page/ActivitiesPage";
import { IngredientsPage } from "../page/IngredientsPage";
import { UsersPage } from "../page/UsersPage";
import { PlaygroundNewAddressCsvPage } from "../page/PlaygroundNewAddressCsvPage";
import { PlaygroundMailingPage } from "../page/PlaygroundMailingPage";

export default function SpeeltuinchefRoute() {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (!isLoadingState && hasValidSession(context)) {
      setIsLoadingState(true);
      checkAndLoadState(context);
    }
  });

  const routes = hasValidSession(context)
    ? state.user === undefined || state.user.email === null
      ? LoadingRoute()
      : LoggedInRoute()
    : OpenRoute();

  return <Router>{routes}</Router>;
}

export const OpenRoute = () => {
  return (
    <Switch>
      <Route path="/">
        <LoginPage />
      </Route>
    </Switch>
  );
};

export const LoggedInRoute = () => {
  return (
    <Switch>
      <Route path="/playgrounds/new">
        <PlaygroundsNewPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/general">
        <PlaygroundsEditGeneralPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/phases">
        <PlaygroundsEditPhasePage />
      </Route>
      <Route path="/playgrounds/:playgroundId/questionnaire/new">
        <PlaygroundNewQuestionnairePage />
      </Route>
      <Route path="/playgrounds/:playgroundId/questionnaire">
        <PlaygroundEditQuestionnairePage />
      </Route>
      <Route path="/playgrounds/:playgroundId/addresses/new">
        <PlaygroundNewAddressPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/addresses/csv">
        <PlaygroundNewAddressCsvPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/addresses">
        <PlaygroundEditAddressesPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/moodboards">
        <PlaygroundEditMoodboards />
      </Route>
      <Route path="/playgrounds/:playgroundId/activities">
        <PlaygroundEditActivitiesPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/mailing">
        <PlaygroundMailingPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/ingredients">
        <PlaygroundEditIngredientsPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/concepts/edit/:conceptId">
        <PlaygroundEditConceptPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/concepts/new">
        <PlaygroundNewConceptPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/concepts">
        <PlaygroundEditConceptsPage />
      </Route>
      <Route path="/playgrounds/:playgroundId/results">
        <PlaygroundResultsPage />
      </Route>
      <Route path="/playgrounds">
        <PlaygroundsPage />
      </Route>
      <Route path="/moodboards">
        <MoodboardsPage />
      </Route>
      <Route path="/activities">
        <ActivitiesPage />
      </Route>
      <Route path="/ingredients">
        <IngredientsPage />
      </Route>
      <Route path="/users">
        <UsersPage />
      </Route>
      <Route path="/">
        <PlaygroundsPage />
      </Route>
    </Switch>
  );
};

export const LoadingRoute = () => {
  return (
    <Switch>
      <Loading />
    </Switch>
  );
};
