import React, { useContext, useEffect, useState } from "react";
import { NavBar } from "../components/organisms/NavBar";
import { Link, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { PlaygroundClientType } from "../models/PlaygroundClientType";
import { Loading } from "../components/molecules/Loading";
import { QuestionList } from "../components/organisms/QuestionList";
import { deleteQuestionFromPlayground } from "../api/questions/QuestionCalls";

export const PlaygroundEditQuestionnairePage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId),
      context
    );
    setApiPlayground(apiPlayground);
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  const deleteQuestion = async (questionId: number) => {
    if (state.session.token && playground) {
      await deleteQuestionFromPlayground(
        state.session.token,
        playground.id,
        questionId
      );
      await fetchPlayground();
    }
  };

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Vragenlijst</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <div>
              <div className={"block__right"}>
                <Link
                  to={"/playgrounds/" + apiPlayground.id + "/questionnaire/new"}
                >
                  <button className={"button"}>Vraag toevoegen</button>
                </Link>
              </div>
              <QuestionList
                questions={apiPlayground.questions}
                deleteQuestion={deleteQuestion}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
