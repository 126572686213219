import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import { EditPlaygroundPhases } from "../components/organisms/EditPlaygroundPhases";

export const PlaygroundsEditPhasePage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  if (playground === null) {
    return <div />;
  }

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Planning</h1>
          <p>Vul hier de planning van de speeltuin in voor de speeltuinchef.</p>
        </div>
        <div className={"block__content"}>
          <EditPlaygroundPhases playground={playground} />
        </div>
      </div>
    </div>
  );
};
