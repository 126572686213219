import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import { NavBar } from "../components/organisms/NavBar";
import { addQuestionToPlayground } from "../api/questions/QuestionCalls";

export const PlaygroundNewQuestionnairePage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("text");
  const [answerOptions, setAnswerOptions] = useState<Array<string>>([]);
  const [answerOption, setAnswerOption] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  if (playground === null) {
    return <div />;
  }

  const addOption = () => {
    if (answerOption !== "") {
      setAnswerOptions([...answerOptions, answerOption]);
      setAnswerOption("");
    }
  };

  const deleteOption = (value: string) => {
    const newAnswerOptions = answerOptions.filter((answerOption) => {
      return answerOption !== value;
    });

    setAnswerOptions(newAnswerOptions);
  };

  const addQuestion = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!state.session.token) {
      setIsLoading(false);
      return;
    }

    try {
      const newQuestion = {
        type: questionType,
        question: question,
        answers: answerOptions,
      };
      await addQuestionToPlayground(
        state.session.token,
        playground.id,
        newQuestion
      );

      history.push("/playgrounds/" + playground.id + "/questionnaire");
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getAnswerOptions = (): React.ReactNode => {
    return (
      <div>
        <div className={"questionList__title"}>Opties</div>
        <div className={"questionList__answerOptions"}>
          {answerOptions.map((answerOption, index) => {
            return (
              <div key={index} className={"questionList__answerOption"}>
                {answerOption}{" "}
                <img
                  src={"/assets/images/menu/trash.svg"}
                  alt={"Plus"}
                  className={"questionList__delete"}
                  onClick={() => {
                    deleteOption(answerOption);
                  }}
                />
              </div>
            );
          })}
        </div>
        <input
          type={"text"}
          value={answerOption}
          placeholder={"Antwoord optie"}
          onChange={(e) => setAnswerOption(e.target.value)}
        />
        <img
          src={"/assets/images/menu/plus-circle.svg"}
          alt={"Plus"}
          className={"questionList__delete questionList__icon"}
          onClick={addOption}
        />
      </div>
    );
  };

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Vragenlijst</h1>
        </div>
        <div className={"block__content"}>
          <form onSubmit={addQuestion}>
            <p>
              <input
                type={"text"}
                placeholder={"Vraag"}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </p>
            <p>
              <select
                value={questionType}
                onChange={(e) => {
                  setQuestionType(e.target.value);
                  if (e.target.value === "text") {
                    setAnswerOptions([]);
                  }
                }}
              >
                <option value={"text"}>Tekstveld</option>
                <option value={"checkbox"}>Aanvink vakjes</option>
                <option value={"select"}>Keuzelijst</option>
              </select>
            </p>
            {questionType !== "text" ? getAnswerOptions() : null}
            <p>
              <button className={"button"}>
                {isLoading ? (
                  <img src={"/assets/images/loader.svg"} />
                ) : (
                  "Toevoegen"
                )}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
