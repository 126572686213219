import React, { useContext, useState } from "react";
import { PlaygroundClientType } from "../../models/PlaygroundClientType";
import { ConceptType } from "../../models/ConceptType";
import { Image } from "../atoms/Image";
import {addConcept, removeConcept, updateConcept} from "../../api/concepts/ConceptCalls";
import { SpeeltuinchefContext } from "../../store/Store";
import { useHistory } from "react-router-dom";
import { ImageUpload } from "../molecules/ImageUpload";
import { ImageType } from "../../models/ImageType";

export type EditPlaygroundConceptProps = {
  playground: PlaygroundClientType;
  concept?: ConceptType | null;
};

export const EditPlaygroundConcept = (props: EditPlaygroundConceptProps) => {
  const { state } = useContext(SpeeltuinchefContext);
  const [title, setTitle] = useState<string>(
    props.concept ? props.concept.title : ""
  );
  const [description, setDescription] = useState<string>(
    props.concept ? props.concept.description : ""
  );
  const [images, setImages] = useState<Array<ImageType>>(
    props.concept ? props.concept.images : []
  );

  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const history = useHistory();

  const removeImage = (imageId: number) => {
    const newImages = images.filter(image => {
      return (image.id !== imageId);
    })

    if(props.concept){
      props.concept.images = newImages;
      setImages(newImages);

      saveConceptInfo();
    }
  }

  const getImages = (): React.ReactNode => {
    if (!props.concept) {
      return <div />;
    } else {
      return images.map((image, index) => {
        return (
          <div key={index} className={"concepts__image"}>
            <Image image={image} />
            <br />
            <span className={"concepts__image__delete"} onClick={()=>{removeImage(image.id)}}>
              Verwijder afbeelding
            </span>{" "}
          </div>
        );
      });
    }
  };

  const saveConceptInfo = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoadingInfo(true);

    if (!state.session.token) {
      setIsLoadingInfo(false);
      return;
    }

    if (props.concept) {
      try {
        await updateConcept(
          state.session.token,
          props.playground,
          {
            title: title,
            description: description,
            images: props.concept.images,
          },
          props.concept.id
        );
        history.push("/playgrounds/" + props.playground.id + "/concepts");
      } catch (e) {
        console.error(e);
        setIsLoadingInfo(false);
      }
    } else {
      try {
        await addConcept(state.session.token, props.playground, {
          title: title,
          description: description,
          images: [],
        });
        setIsLoadingInfo(false);
        history.push("/playgrounds/" + props.playground.id + "/concepts");
      } catch (e) {
        console.error(e);
        setIsLoadingInfo(false);
      }
    }
  };

  const deleteConcept = async () => {
    if (!state.session.token || !props.concept) {
      return;
    }
    try {
      await removeConcept(state.session.token, props.playground, props.concept.id)
      history.push("/playgrounds/" + props.playground.id + "/concepts");
    } catch (e) {
      console.error(e);
    }

  }

  const addImage = async (image: ImageType) => {
    if (props.concept) {
      const newImages = [...images, image];

      props.concept.images = newImages;
      await setImages(newImages);
      saveConceptInfo();
    }
  };

  return (
    <div className={"concepts"}>
      <div className={"concepts__general"}>
        <form onSubmit={saveConceptInfo}>
          <input
            type={"text"}
            placeholder={"Concept naam"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <textarea
            placeholder={"Concept omschrijving"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <p>
            <button className={"button"}>
              {isLoadingInfo ? (
                <img src={"/assets/images/loader.svg"} />
              ) : (
                "Opslaan"
              )}
            </button>
          </p>
        </form>
      </div>
      {props.concept ? (
        <div className={"concepts__images"}>
          <h2>Afbeeldingen</h2>

          {getImages()}

          <ImageUpload maxUploadKB={5120} addImage={addImage} />

          <button className={"button-secondary"} onClick={deleteConcept}>
                Verwijder concept
          </button>
        </div>
      ) : null}
    </div>
  );
};
