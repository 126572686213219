import { CheckBox } from "../components/atoms/Checkbox";
import React, { useContext, useState } from "react";
import { SpeeltuinchefContext } from "../store/Store";
import { postLogin } from "../api/user/UserCalls";
import { useHistory } from "react-router-dom";

export const LoginPage = () => {
  const history = useHistory();
  const context = useContext(SpeeltuinchefContext);
  const { dispatch } = context;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [correctLogin, setCorrectLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postLogin({
        email: email,
        password: password,
      });

      if (response.error) {
        setCorrectLogin(false);
        setIsLoading(false);

        return;
      }

      dispatch({ type: "SET_SESSION_TOKEN", payload: response.token });
      dispatch({ type: "SET_USER", payload: response.user });

      if (rememberMe) {
        localStorage.setItem("speeltuinchef-session", response.token);
      } else {
        sessionStorage.setItem("speeltuinchef-session", response.token);
      }

      history.push("/playgrounds");
    } catch (e) {
      setCorrectLogin(false);
      setIsLoading(false);
    }
  };

  const loginWarning = correctLogin ? null : (
    <div className={"message message--warning"} role={"alert"}>
      Inloggen mislukt, controleer uw gegevens!
    </div>
  );

  return (
    <div className={"login"}>
      <div className={"login__logo"}>
        <img src="/assets/images/logo_speeltuinchef.png" alt="Logo chefkok" />
      </div>
      <div className={"login__title"}>
        <h1>Speeltuinchef beheer</h1>
      </div>
      <div className={"login__form"}>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="E-mailadres"
            placeholder={"E-mail"}
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Wachtwoord"
            placeholder={"Wachtwoord"}
          />
          <div className={"login__rememberMe"}>
            <CheckBox
              text={"Onthoud me"}
              checked={rememberMe}
              onChangeSetStateFunction={setRememberMe}
              ariaText={"Onthoud me"}
            />
          </div>
          {loginWarning}
          <button className={"button"}>
            {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Go!"}
          </button>
          <p>
            <a href={process.env.REACT_APP_FE_BASE_URL + "/password-forgot"}>
              Wachtwoord vergeten?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
