import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { getPlaygroundsCenterLocation } from "../../helpers/PlaygroundHelper";
import { LocationType } from "../../models/LocationType";

export type MarkerProps = {
  lat: number | undefined;
  lng: number | undefined;
};
const Marker = (props: MarkerProps) => (
  <div>
    <img src={"/assets/images/slide.png"} alt={"Speeltuin op " + props.lat} />
  </div>
);

export type MapViewPlaygroundsProps = {
  playgrounds: Array<PlaygroundType> | null;
  setLocation: (lat: number, lon: number) => void;
  startLocation?: LocationType | null;
};

export const MapViewNewPlayground = (props: MapViewPlaygroundsProps) => {
  const fallbackLocation = { lat: 52.074, lng: 5.175 };
  const mapCenter =
    props.startLocation && props.startLocation.lat && props.startLocation.lon
      ? { lat: props.startLocation.lat, lng: props.startLocation.lon }
      : props.playgrounds === null
      ? fallbackLocation
      : getPlaygroundsCenterLocation(props.playgrounds);

  const [lat, setLat] = useState(
    isNaN(mapCenter.lat) ? fallbackLocation.lat : mapCenter.lat
  );
  const [lng, setLng] = useState(
    isNaN(mapCenter.lng) ? fallbackLocation.lng : fallbackLocation.lng
  );

  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_KEY
    : "";

  const _mapClick = (e: any) => {
    setLat(e.lat);
    setLng(e.lng);
    props.setLocation(e.lat, e.lng);
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: 500, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey }}
        defaultCenter={mapCenter}
        defaultZoom={13}
        onClick={_mapClick}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
