import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundDetailsById,
} from "../helpers/PlaygroundHelper";
import { PlaygroundClientType } from "../models/PlaygroundClientType";

import { NavBar } from "../components/organisms/NavBar";
import { Loading } from "../components/molecules/Loading";
import { ConceptList } from "../components/organisms/ConceptList";

export const PlaygroundEditConceptsPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const [
    apiPlayground,
    setApiPlayground,
  ] = useState<PlaygroundClientType | null>(null);

  const fetchPlayground = async () => {
    const apiPlayground = await getPlaygroundDetailsById(
      parseInt(playgroundId),
      context
    );
    setApiPlayground(apiPlayground);
  };

  useEffect(() => {
    if (apiPlayground === null) {
      fetchPlayground();
    }
  });

  return (
    <div>
      <NavBar playground={playground} />
      <div className={"block"}>
        <div className={"block__title"}>
          <h1>Concepten</h1>
        </div>
        <div className={"block__content"}>
          {apiPlayground === null ? (
            <Loading />
          ) : (
            <div>
              <div className={"block__right"}>
                <Link to={"/playgrounds/" + apiPlayground.id + "/concepts/new"}>
                  <button className={"button"}>Voeg concept toe</button>
                </Link>
              </div>
              <ConceptList
                concepts={apiPlayground.concepts}
                playground={apiPlayground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
