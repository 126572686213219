import React from "react";
import GoogleMapReact from "google-map-react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { getPlaygroundsCenterLocation } from "../../helpers/PlaygroundHelper";
import { Link } from "react-router-dom";

export type MarkerProps = {
  playground: PlaygroundType;
  lat: number | undefined;
  lng: number | undefined;
};
const Marker = ({ playground }: MarkerProps) => (
  <div>
    <Link to={"/playgrounds/" + playground.id + "/general"}>
      <img src={"/assets/images/slide.png"} />
    </Link>
  </div>
);

export type MapViewPlaygroundsProps = {
  playgrounds: Array<PlaygroundType>;
};

export const MapViewPlaygrounds = (props: MapViewPlaygroundsProps) => {
  const mapCenter = getPlaygroundsCenterLocation(props.playgrounds);
  const getMarkers = () => {
    return props.playgrounds.map((playground) => {
      return (
        <Marker
          key={"marker-" + playground.id}
          lat={playground.location?.lat}
          lng={playground.location?.lon}
          playground={playground}
        />
      );
    });
  };

  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_KEY
    : "";

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: 500, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey }}
        defaultCenter={mapCenter}
        defaultZoom={13}
      >
        {getMarkers()}
      </GoogleMapReact>
    </div>
  );
};
